import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="page404">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... &#128546;</p>
      <p>
        Try this{" "}
        <a
          href="https://donnigarvida.netlify.app/"
        >
          page
        </a>{" "}
      </p>
      <p>
        Or you can check me on{" "}
        <a
          href="https://www.linkedin.com/in/donni-garvida/"
        >
          Linkedin
        </a>{" "}
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
